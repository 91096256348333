<template>
    <div class="control input-containers">
        <input
            :placeholder="placeholder"
            type="text"
            class="input relative"
            :class="{ focused: focus }"
            ref="searchInput"
            @input="autocomplete"
            @blur="handleBlur"
            @focus="handleFocus"
            autocapitalize="off"
            autocorrect="off"
            autocomplete="off"
            spellcheck="false"
            :v-validate="required && 'required'"
            data-vv-delay="1000"
            name="search"
            :disabled="isGlobalClientSelected"
        />
        <span @click="iconClick()" class="absolute cursor-pointer mt-2 -ml-6" style=" color: rgba(65, 87, 112)"><slot /></span>
        <p class="help is-danger">{{ errors.first("search") }}</p>

        <transition name="grow">
            <div ref="dropdown" class="results-container" v-if="suggestions.length != 0">
                <div :key="key" v-for="(resultType, key) in suggestions" ref="dropdownOption">
                    <div @mousedown="getClientDetails(resultType)" class="item-container" :class="{ highlighted: key === selectedIndex }" v-html="resultType.html" />
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            text: "",
            focus: false,
            autocompleteDebounce: undefined,
            suggestions: [],
            selectedClientId: undefined,
            selectedIndex: 0,
        };
    },

    props: {
        options: {
            type: Array,
        },
        placeholder: {
            type: String,
        },
        selected: {
            type: Object,
        },
        showError: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        this.$watch(propValue => {
            this.init(propValue.selected);
        });
    },

    methods: {
        iconClick() {
            this.$emit("iconClick");
        },

        init(selectedValue) {
            if (this.options && selectedValue?.value) {
                let currentOption = this.options.find(option => option.value === selectedValue.value);
                if (currentOption && this.$refs.searchInput) this.$refs.searchInput.value = currentOption.name;
                return;
            }
            if (this.options && selectedValue != undefined && selectedValue.name != "") {
                let currentOption = this.options.find(option => option.value === selectedValue.value);
                if (currentOption && this.$refs.searchInput) this.$refs.searchInput.value = currentOption.name;
            } else {
                this.$refs.searchInput.value = "";
            }
        },
        handleFocus() {
            this.focus = true;
            this.selectedIndex = 0;
            this.$refs.searchInput.select();
            window.addEventListener("keydown", this.traverseOptions);
        },
        handleBlur() {
            this.focus = false;
            this.suggestions = [];
            this.selectedIndex = 0;
            this.$refs.searchInput.blur();
            window.removeEventListener("keydown", this.traverseOptions);
        },
        traverseOptions(e) {
            switch (e.keyCode) {
                case 27: // esc
                    e.preventDefault();
                    this.triggerBlur();
                    break;
                case 13: {
                    // enter
                    e.preventDefault();
                    let item = this.suggestions[this.selectedIndex];
                    this.getClientDetails(item);
                    this.handleBlur();
                    break;
                }
                case 9: // tab
                    e.preventDefault();
                    break;
                case 40: // down arrow
                    e.preventDefault();
                    this.traverseDown();
                    break;
                case 38: // up arrow
                    e.preventDefault();
                    this.traverseUp();
                    break;
                default:
                    break;
            }
        },
        traverseDown() {
            const length = this.suggestions.length;
            if (this.selectedIndex < length - 1) this.selectedIndex++;
            this.scrollToElement(this.$refs.dropdownOption[this.selectedIndex]);
        },
        traverseUp() {
            if (this.selectedIndex > 0) this.selectedIndex--;
            this.scrollToElement(this.$refs.dropdownOption[this.selectedIndex]);
        },
        scrollToElement(element) {
            const dropdown = this.$refs.dropdown;
            const scrollTop = dropdown.scrollTop;
            const scrollBounds = {
                top: scrollTop,
                bottom: scrollTop + dropdown.clientHeight,
            };

            const elementBounds = {
                top: element.offsetTop,
                bottom: element.offsetTop + element.clientHeight,
            };

            if (elementBounds.top < scrollBounds.top) {
                dropdown.scrollTop = elementBounds.top;
            } else if (elementBounds.bottom > scrollBounds.bottom) {
                dropdown.scrollTop = elementBounds.bottom - dropdown.clientHeight;
            }
        },
        autocomplete(event) {
            this.text = event.target.value;
            if (this.text == "") {
                this.$emit("on-selected", this.text);
            }
            clearTimeout(this.autocompleteDebounce);
            this.autocompleteDebounce = setTimeout(() => {
                const regexp = new RegExp(`${this.text}`, "iu");
                this.suggestions = this.options.filter(suggestion => suggestion.name.match(regexp));
                this.suggestions.forEach(element => {
                    const found = element.name.match(regexp)[0];
                    element.html = element.name.replace(regexp, "<b>" + found + "</b>");
                });
                this.selectedIndex = 0;
            }, 500);
        },
        getClientDetails(result) {
            this.$refs.searchInput.value = result.name;
            this.$emit("on-selected", result);
        },
    },
    computed: {
        rules() {
            return this.showError ? "required" : "";
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
<style scoped>
.grow-enter-active,
.grow-leave-active {
    transition: max-height 0.3s, padding 0.2s;
}
.grow-enter,
.grow-leave-to {
    max-height: 0;
    padding: 0;
}
.item-container {
    padding: 5px;
}
.results-container {
    cursor: pointer;
    max-height: 400px;
    overflow-y: auto;
    max-width: 500px;
    font-weight: 400;
    padding: 20px;
    z-index: 10000;
    padding-top: 0;
    margin-left: 100px;
    position: absolute;
    top: 40px;
    left: -100px;
    width: 500px;
    background: white;
    border: solid 1px #cadeea;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
}
.highlighted {
    background: #e7f5fe;
}
.input-containers {
    position: relative;
}
.focused {
    /* border: solid 2px #14abf4; */
    background: #e7f5fe;
    /* -moz-user-focus: 0; */
}
</style>
